/* Home */
.home {
    height: calc(100vh - 3rem);
    row-gap: 1rem;
}
.home__data {
    align-self: center;
}
.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-5);
}
.home__title-color {
    color: var(--first-color);
}
.home__social {
    display: flex;
    flex-direction: column;
}
.home__social-icon {
    width: max-content;
    margin-bottom: var(--mb-2);
    font-size: 1.5rem;
    color: var(--second-color);
}
.home__social-icon:hover {
    color: var(--first-color);
    transition: .3s;
}
.home__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 275px;
}

/* ABOUT SECTION*/
.about__container {
    row-gap: 2rem;
    text-align: center;
}
.about__subtitle {
    margin-bottom: var(--mb-2);
}
.about__img {
    justify-self: center;
}
.about__img img {
    width: 200px;
    border-radius: .5rem;
}

@media screen and (min-width: 768px) {
    /* HOME SECTION */
    .home {
        height: 100vh;
    }
    .home__data {
        align-self: flex-end;
    }
    .home__social {
        padding-top: 0;
        padding-bottom: 2.5rem;
        flex-direction: row;
        align-self: flex-end;
    }
    .home__social-icon {
        margin-bottom: 0;
        margin-right: var(--mb-4);
    }
    .home__img {
        width: 380px;
        bottom: 0;
    }

    /* ABOUT SECTION */
    .about__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: initial;
    }
    .about__img img {
        width: 300px;
    }
}

@media screen and (min-width: 1024px) {
    /* HOME SECTION */
    .home__img {
        right: 10%;
    }
}