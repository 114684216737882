/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Variables CSS */
:root {
  --header-height: 3rem;
  --font-semi: 600;
}

/* Colors */
:root {
  --first-color: #4070f4;
  --second-color: #0e2431;
}

/* Font and typography */
:root {
  --body-font: 'Poppins', sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px) {
  :root {
      --big-font-size: 3.5rem;
      --h2-font-size: 2rem;
      --normal-font-size: 1rem;
  }
}

/* Margins */
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/* z-index */
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Base */
*, ::before, ::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--second-color);
}
h1, h2, p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Class CSS */
.section-title {
  position: relative;
  font-size: var(--h2-font-size);
  color: var(--first-color);
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: center;
}
.section-title::after {
  position: absolute;
  content: '';
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: var(--first-color);
}
.section {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

/* Layout */
.bd-grid {
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: #FFF;
  box-shadow: 0 1px 4px rgba(146, 161, 176, .15);
}

/* Buttons */
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #FFF;
  padding: 0.75rem 2.5rem;
  font-weight: var(--font-semi);
  border-radius: .5rem;
  transition: .3s;
}
.button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
  background-color: #4070f4ed;
}

/* Media Queries */
@media screen and (min-width: 768px) {
  body {
      margin: 0;
  }
  .section {
      padding-top: 4rem;
      padding-bottom: 3rem;
  }
  .section-title {
      margin-bottom: var(--mb-4);
  }
  .section-title::after {
      width: 80px;
      top: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .bd-grid {
      margin-left: auto;
      margin-right: auto;
  }
}