@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
/* Google Fonts */

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Variables CSS */
:root {
  --header-height: 3rem;
  --font-semi: 600;
}

/* Colors */
:root {
  --first-color: #4070f4;
  --second-color: #0e2431;
}

/* Font and typography */
:root {
  --body-font: 'Poppins', sans-serif;
  --big-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
}
@media screen and (min-width: 768px) {
  :root {
      --big-font-size: 3.5rem;
      --h2-font-size: 2rem;
      --normal-font-size: 1rem;
  }
}

/* Margins */
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/* z-index */
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Base */
*, ::before, ::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 3rem 0 0 0;
  margin: var(--header-height) 0 0 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  color: #0e2431;
  color: var(--second-color);
}
h1, h2, p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Class CSS */
.section-title {
  position: relative;
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  color: #4070f4;
  color: var(--first-color);
  margin-top: 1rem;
  margin-top: var(--mb-2);
  margin-bottom: 2rem;
  margin-bottom: var(--mb-4);
  text-align: center;
}
.section-title::after {
  position: absolute;
  content: '';
  width: 64px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin: auto;
  top: 2rem;
  background-color: #4070f4;
  background-color: var(--first-color);
}
.section {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

/* Layout */
.bd-grid {
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-left: var(--mb-2);
  margin-right: 1rem;
  margin-right: var(--mb-2);
}
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: #FFF;
  box-shadow: 0 1px 4px rgba(146, 161, 176, .15);
}

/* Buttons */
.button {
  display: inline-block;
  background-color: #4070f4;
  background-color: var(--first-color);
  color: #FFF;
  padding: 0.75rem 2.5rem;
  font-weight: 600;
  font-weight: var(--font-semi);
  border-radius: .5rem;
  transition: .3s;
}
.button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, .15);
  background-color: #4070f4ed;
}

/* Media Queries */
@media screen and (min-width: 768px) {
  body {
      margin: 0;
  }
  .section {
      padding-top: 4rem;
      padding-bottom: 3rem;
  }
  .section-title {
      margin-bottom: 2rem;
      margin-bottom: var(--mb-4);
  }
  .section-title::after {
      width: 80px;
      top: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .bd-grid {
      margin-left: auto;
      margin-right: auto;
  }
}
/* Footer */
.footer {
    background-color: var(--second-color);
    color: #FFF;
    text-align: center;
    font-weight: var(--font-semi);
    padding: 2rem 0;
}
.footer__title {
    font-size: 2rem;
    margin-bottom: var(--mb-4);
}
.footer__social {
    margin-bottom: var(--mb-4);
}
.footer__icon {
    font-size: 1.5rem;
    color: #FFF;
    margin: 0 var(--mb-2);
    transition: all .4s ease-in-out;
}
.footer__icon:hover {
    padding: .1rem;
    background-color: #FFF;
    border-radius: 50%;
    color: var(--first-color);
}
.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);
}
@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: var(--header-height);
        right: -100%;
        width: 80%;
        height: 100%;
        padding: 2rem;
        background-color: var(--second-color);
        transition: .5s;
    }
}
.nav__item {
    margin-bottom: var(--mb-4);
}
.nav__link {
    position: relative;
    color: #FFF;
}
.nav__link:hover {
    position: relative;
    color: var(--first-color);
    transition: .3s;
}
.nav__link::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.25rem;
    left: 0;
    top: 2rem;
    background-color: transparent;
    transition: all .3s ease-in-out;
}
.nav__link:hover::after {
    background-color: var(--first-color);
}
.nav__logo {
    color: var(--second-color);
    font-size: var(--mb-3);
    transition: all .3s ease-in-out;
}
.nav__logo:hover {
    color: var(--first-color);
    transition: .3s;
}
.nav__toggle {
    color: var(--second-color);
    font-size: 1.5rem;
    cursor: pointer;
}

/* Active menu */
.active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.25rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}

/* Show menu */
.show {
    right: 0;
}

@media screen and (min-width: 768px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }
    .nav__list {
        display: flex;
        padding-top: 0;
    }
    .nav__item {
        margin-left: var(--mb-6);
        margin-bottom: 0;
    }
    .nav__toggle {
        display: none;
    }
    .nav__link {
        color: var(--second-color);
        transition: all .3s ease-in-out;
    }
}
.contact__input {
    width: 100%;
    font-size: var(--normal-font-size);
    font-weight: var(--font-semi);
    padding: var(--mb-2);
    border-radius: var(--mb-1);
    border: 1.5px solid var(--second-color);
    outline: none;
    margin-bottom: var(--mb-1);
}
.contact__button {
    display: block;
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
    cursor: pointer;
    margin-left: auto;
    transition: all .3s ease-in-out;
}

.wpp-info .bxl-whatsapp {
    margin-right: var(--mb-1);
}
.wpp-info {
    text-align: center;
    padding: .25rem 1rem;
    margin: 0;
    line-height: 1;
}
.wpp-info a {
  color: #000;
  transition: all .3s ease-in-out;
}
.wpp-info a:hover,
.wpp-info a:active,
.wpp-info a:focus {
  color: var(--first-color);
  transition: .3s;
}

.status {
    position: relative;
    font-size: var(--normal-font-size);
    padding: 0.5rem 1.5rem;
    margin-top: var(--mb-1);
    border-radius: var(--mb-1);
    opacity: 0;
    transition: all .75s ease-in-out;
}
.status.success {
    background: #69dc69;
    display: block;
}
.status.error {
    background: #ef3a3a;
    display: block;
}
.fade {
    opacity: 1;
}
.status .status-text-success,
.status .status-text-error {
    line-height: 1;
    font-weight: var(--font-semi);
    color: #FFF;
    text-align: center;
    display: none;
}
.status .status-text-success .bx-check-double,
.status .status-text-error .bx-error-circle {
    font-weight: var(--font-semi);
    margin-right: var(--mb-1);
}
.status.success .status-text-success,
.status.error .status-text-error {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    position: absolute;
    display: none;
}
.loader.active-load {
    display: block;
}
.loader .bx-loader-circle {
    font-size: 2rem;
    color: #4070f4ed;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
            transform: rotate(0deg);
    }
    to {
            transform: rotate(359deg);
    }
}

@media screen and (min-width: 768px) {
    .contact__form {
        width: 360px;
    }
    .contact__container {
        margin-top: var(--mb-3);
        justify-items: center;
    }
}
/* Home */
.home {
    height: calc(100vh - 3rem);
    grid-row-gap: 1rem;
    row-gap: 1rem;
}
.home__data {
    align-self: center;
}
.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-5);
}
.home__title-color {
    color: var(--first-color);
}
.home__social {
    display: flex;
    flex-direction: column;
}
.home__social-icon {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: var(--mb-2);
    font-size: 1.5rem;
    color: var(--second-color);
}
.home__social-icon:hover {
    color: var(--first-color);
    transition: .3s;
}
.home__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 275px;
}

/* ABOUT SECTION*/
.about__container {
    grid-row-gap: 2rem;
    row-gap: 2rem;
    text-align: center;
}
.about__subtitle {
    margin-bottom: var(--mb-2);
}
.about__img {
    justify-self: center;
}
.about__img img {
    width: 200px;
    border-radius: .5rem;
}

@media screen and (min-width: 768px) {
    /* HOME SECTION */
    .home {
        height: 100vh;
    }
    .home__data {
        align-self: flex-end;
    }
    .home__social {
        padding-top: 0;
        padding-bottom: 2.5rem;
        flex-direction: row;
        align-self: flex-end;
    }
    .home__social-icon {
        margin-bottom: 0;
        margin-right: var(--mb-4);
    }
    .home__img {
        width: 380px;
        bottom: 0;
    }

    /* ABOUT SECTION */
    .about__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: left;
        text-align: initial;
    }
    .about__img img {
        width: 300px;
    }
}

@media screen and (min-width: 1024px) {
    /* HOME SECTION */
    .home__img {
        right: 10%;
    }
}
.skills__container {
    grid-row-gap: 2rem;
    row-gap: 2rem;
    text-align: center;
}
.skills__subtitle {
    margin-bottom: var(--mb-2);
}
.skills__text {
    margin-bottom: var(--mb-4);
}
.skills__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: var(--font-semi);
    padding: .5rem 1rem;
    margin-bottom: var(--mb-4);
    border-radius: .5rem;
    box-shadow: 0 4px 25px rgba(14, 36, 49, .15);
}
.skills__icon {
    font-size: 2rem;
    margin-right: var(--mb-2);
    color: var(--first-color);
}
.skills__names {
    display: flex;
    align-items: center;
}
.skills__bar {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--first-color);
    height: .25rem;
    border-radius: .5rem;
    z-index: var(--z-back);
}
.skills__html {
    width: 85%;
}
.skills__css {
    width: 60%;
}
.skills__bs {
    width: 60%;
}
.skills__js {
    width: 35%;
}
.skills__jq {
    width: 25%;
}
.skills__wp {
    width: 30%;
}
.skills__mage {
    width: 30%;
}
.skills__react {
    width: 35%;
}
.skills__node {
    width: 10%;
}
.skills__py {
    width: 10%;
}
.skills__git {
    width: 10%;
}
.skills__img {
    border-radius: .5rem;
}

@media screen and (min-width: 768px) {
    .skills__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: left;
        text-align: initial;
    }
}
.work {
    text-align: center;
}
.work__container {
    grid-row-gap: 2rem;
    row-gap: 2rem;
}
.work__img {
    box-shadow: 0 4px 25px rgba(14, 36, 49, .15);
    border-radius: .5rem;
    overflow: hidden;
    width: 100%;
    height: 213px;
}
.work__img img {
    transition: 1s;
    cursor: pointer;
    height: inherit;
  width: 100%;
}
.work__img img:hover {
    transform: scale(1.1);
}
#work .job-title {
  color: #000;
}
#work .about__text {
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
    .work__container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }
}
