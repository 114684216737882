.work {
    text-align: center;
}
.work__container {
    row-gap: 2rem;
}
.work__img {
    box-shadow: 0 4px 25px rgba(14, 36, 49, .15);
    border-radius: .5rem;
    overflow: hidden;
    width: 100%;
    height: 213px;
}
.work__img img {
    transition: 1s;
    cursor: pointer;
    height: inherit;
  width: 100%;
}
.work__img img:hover {
    transform: scale(1.1);
}
#work .job-title {
  color: #000;
}
#work .about__text {
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
    .work__container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 2rem;
    }
}