.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);
}
@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: var(--header-height);
        right: -100%;
        width: 80%;
        height: 100%;
        padding: 2rem;
        background-color: var(--second-color);
        transition: .5s;
    }
}
.nav__item {
    margin-bottom: var(--mb-4);
}
.nav__link {
    position: relative;
    color: #FFF;
}
.nav__link:hover {
    position: relative;
    color: var(--first-color);
    transition: .3s;
}
.nav__link::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.25rem;
    left: 0;
    top: 2rem;
    background-color: transparent;
    transition: all .3s ease-in-out;
}
.nav__link:hover::after {
    background-color: var(--first-color);
}
.nav__logo {
    color: var(--second-color);
    font-size: var(--mb-3);
    transition: all .3s ease-in-out;
}
.nav__logo:hover {
    color: var(--first-color);
    transition: .3s;
}
.nav__toggle {
    color: var(--second-color);
    font-size: 1.5rem;
    cursor: pointer;
}

/* Active menu */
.active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 0.25rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}

/* Show menu */
.show {
    right: 0;
}

@media screen and (min-width: 768px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }
    .nav__list {
        display: flex;
        padding-top: 0;
    }
    .nav__item {
        margin-left: var(--mb-6);
        margin-bottom: 0;
    }
    .nav__toggle {
        display: none;
    }
    .nav__link {
        color: var(--second-color);
        transition: all .3s ease-in-out;
    }
}